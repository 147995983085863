.anli {
  // background: linear-gradient(131deg, #f6feff 0%, #ffefef 100%);
  width: 100%;
  overflow: hidden;
  // height: 800px;
  height: 800px;
  // padding: 80px 0;

  overflow: hidden;
  min-height: auto;
  box-sizing: border-box;
  background: white;

  .activepic {
    width: 100%;
    height: 800px;

    span {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.myPagination {
  position: absolute;
  // left: 12px !important;
  // top: calc(50% - 40px) !important;
  bottom: 50px !important;
}

.myBullet {
  // width: 23px !important;
  // height: 3px !important;
  // border-radius: 2px !important;
  // background-color: rgba($color: #000000, $alpha: 0.3);
  // margin: 0px !important;
}

.myBullet {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px !important;
  // background-color: rgba($color: #000000, $alpha: 0.3);
  background: rgba(255, 255, 255, 0.5) !important;
  margin: 0 5px !important;
  opacity: unset !important;
}

.myActiveBullet {
  // width: 100px !important;
  // background-color: #ff3e5f !important;

  background: #ffffff !important;
}

.myNavigationPrev,
.myNavigationNext {
  // color: #010517 !important;
  --swiper-navigation-size: 10px;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
  background: #ff3e5f;
  color: #fff !important;
  top: calc(50% - 30px) !important;

  &::after {
    line-height: 36px;
  }
}

.myNavigationPrev {
  left: 100px !important;
}

.myNavigationNext {
  right: 100px !important;
}

.myNavigationDisabled {
  color: #010517 !important;
  border: 1px solid #182230;
  opacity: 0.2;
  background: #fff;
}

@media screen and (max-width: 1400px) {
  .myPagination {
    position: absolute;
    // left: 12px !important;
    // top: calc(50% - 40px) !important;
    bottom: 60px !important;
  }

  .myNavigationPrev {
    left: 25px !important;
  }

  .myNavigationNext {
    right: 25px !important;
  }

  .anli {
    // height: 700px !important;
    height: 740px !important;
    .activepic {
      width: 100%;
      height: 600px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .myPagination {
    position: absolute;
    // left: 12px !important;
    // top: calc(50% - 40px) !important;
    bottom: 20px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .anli {
    height: 700px !important;

    .activepic {
      width: 100%;
      height: 600px;
    }
  }

  .myPagination {
    position: absolute;
    // left: 12px !important;
    // top: calc(50% - 40px) !important;
    bottom: unset !important;
    top: 640px !important;
  }
}

@media screen and (max-width: 1600px) {
  .myNavigationPrev {
    left: 25px !important;
  }

  .myNavigationNext {
    right: 25px !important;
  }
}
