@import '@/pages/va.module';

.main {
  height: 800px;
  overflow: hidden;
  min-height: 600px;
  .new {
    right: -13px;
    top: -10px;
    background: #fff697;
    color: #ff3e5f;
    font-weight: bold;
    height: 26px;
    font-size: 18px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 8px 8px 8px 0;
    position: absolute;
    font-family: "Noto Sans Display Blod";
    font-style: italic;
  }
  &.main_b1 {
    background: linear-gradient(120deg, #ffe8d4, #ffb1b2);
    .topwrap {
      justify-content: center;
      width: 100%;
    }
    .b1_title {
      max-width: 919px;
      @include dvs('max-width: 1500px') {
        max-width: 510px !important;
      }
    }
    .btn_b1 {
      font-weight: bold !important;
      margin-top: 63px !important;
      font-size: 26px !important;
      width: 188px !important;
      @include dvs('max-width: 750px') {
        margin: 40px auto !important;
        width: 200px !important;
        height: 50px !important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    height: 700px !important;
  }

  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    height: 700px !important;
  }

  background: linear-gradient(132deg, #88d9dd 0%, #fff5d9 62%, #ffc0b1 100%);

  .top {
    height: 100%;
    position: relative;
  }

  .topwrap {
    height: 100%;
    position: absolute;
    bottom: 0;
  }

  // @include dv($mid) {
  //     height: 90vh;
  // }
  .left {
    position: absolute;

    @include dv($sm) {
      margin-top: 40px;
    }

    @include dv($mid) {
      margin-top: 10px;
    }

    .title1 {
      max-width: 512px;
      font-size: 42px;

      // font-family: 'Noto Sans Display Blod';
      font-family: 'Noto Sans Display Blod';
      font-weight: bold;
      color: #191919;
      line-height: 70px;

      z-index: 10;
      position: relative;

      @include dv($big) {
        max-width: 620px;
        font-size: 56px;
        line-height: 84px;
      }
    }

    .start {
      margin-top: 76px;
      width: 232px;
      height: 55px;
      background: #ff3e5f;
      box-shadow: 0 3px 6px 0px #f2b8c5;
      border-radius: 35px;
      line-height: 55px;
      text-align: center;
      display: inline-block;
      font-size: 19px;
      // font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        opacity: 0.8 !important;
      }

      @media screen and (max-width: 1400px) {
        margin-top: 46px;
      }
    }
  }

  .tip-box {
    width: 211px;
    height: 97px;
    background: linear-gradient(
      90deg,
      rgba(254, 27, 82, 0.78) 0%,
      rgba(255, 171, 123, 0.72) 74%,
      rgba(255, 183, 72, 0.9) 100%
    );
    border-radius: 18px;
    backdrop-filter: blur(21px);
    position: absolute;
    bottom: 18%;
    z-index: 2;
    padding-left: 45px;
    box-sizing: border-box;
    left: 0;
    color: #ffffff;
    margin-top: 8px;
    font-size: 32px;
    line-height: 32px;
    font-family: Manrope-Bold, Manrope;
    margin-top: 8px;

    @media screen and (max-width: $sm) {
      // width: 281px;
      // height: 129px;
    }

    @include dv(1360px) {
      bottom: 16%;
    }

    @include dv($mid) {
      bottom: 30%;
    }

    .smallcn {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }

    .title {
      display: flex;
      align-items: center;
      padding-top: 10%;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 20px;

      @media screen and (min-width: $mid) {
        padding-top: 12%;
      }

      @media screen and (min-width: $big) {
        padding-top: 10%;
        font-size: 20px;
      }
    }
  }

  .msk-box {
    width: 234px;
    position: absolute;
    right: -50px;
    bottom: 8.3vh;
    z-index: 2;
    height: 261px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.86) 0%,
      rgba(255, 255, 255, 0.6) 100%
    );
    border-radius: 20px;
    border: 2px solid #ffffff;
    backdrop-filter: blur(17.64px);
    padding: 20px 28px;

    .msk-io {
      position: absolute;
      left: 28px;
      bottom: 120px;
      z-index: 3;

      .icon {
        width: 34px;
        height: 34px;
        position: relative;
      }
    }

    .msk-title {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 21px;
    }

    .msk-desc {
      font-size: 27px;
      font-family: Manrope-Bold, Manrope;
      font-weight: bold;
      color: #191919;
      line-height: 37px;
    }

    .line-card {
      display: flex;
      height: 117px;
      align-items: flex-end;
      margin-top: 35px;

      .line {
        flex: 1;
        height: 100%;
        width: 17%;
        margin-right: 3%;
        border-radius: 3px;
        background: rgba(255, 10, 10, 0.28);

        &:nth-child(1) {
          height: 60px;
        }

        &:nth-child(2) {
          height: 36px;
        }

        &:nth-child(3) {
          height: 73px;
        }

        &:nth-child(4) {
          background: linear-gradient(180deg, #ff6c3e 0%, #ff3e5f 100%);
        }

        &:last-child {
          height: 65px;
          margin-left: 0;
        }
      }
    }
  }

  .right {
    position: relative;
    margin-left: 40%;

    @include dv($mid) {
      margin-left: 50%;
    }

    @include dv($sm) {
      margin-left: 70%;
    }

    @media screen and (min-width: 1270px) and (max-width: 1380px) {
      margin-left: 56%;
    }

    .pic {
      position: relative;
      width: 722px;
      top: 8vh;

      @media screen and (max-width: $sm) {
        position: relative;
        width: 692px;
      }

      @media screen and (min-width: $big) {
        width: 800px;
        position: relative;
      }

      @media screen and (max-width: 1366px) {
        top: 15vh !important;
      }
    }
  }

  .left2 {
    .title1 {
      font-size: 50px;
      // font-family: Popins;

      color: #191919;
      line-height: 60px;
      // font-family: 'Noto Sans Display Blod';
      font-family: 'Noto Sans Display Blod';
    }

    .title2 {
      font-size: 20px;
      // font-family: PingFangSC;
      color: #191919;
      line-height: 30px;

      margin-top: 30px;
    }

    .title3 {
      font-size: 20px;
      // font-family: PingFangSC;
      color: #191919;
      line-height: 30px;

      margin-top: 30px;
    }

    .btn {
      width: 372px;
      height: 65px;
      background: linear-gradient(90deg, #ff52aa 0%, #ff3e5f 100%);
      border-radius: 35px;
      font-size: 24px;
      // font-family: Poppins;
      // font-family: Poppins-Bold, Poppins;
      color: #fff2a4;
      // line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      margin-top: 40px;
    }
  }

  .right2 {
    position: relative;

    .pic {
      position: relative;
      width: 630px;
      top: 5vh;

      // @media screen and (max-width: $sm) {
      //     position: relative;
      //     width: 692px;
      // }

      // @media screen and (min-width: $big) {
      //     width: 800px;
      //     position: relative;
      // }

      @media screen and (max-width: 1366px) {
        // top: 15vh !important;
      }
    }
  }
}

.activity {
  // background: linear-gradient(177deg, #e7d9ff 0%, #6c45e2 100%);
  background-image: url('/images/banner/gift-activity-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  .activity-bags {
    font-size: 31px;
    color: #000000;
    @include cfont(500);
  }
  .activity-title {
    font-size: 45px;
    line-height: 54px;
    @include cfont(600);
    color: #ffffff;
  }
  .activity-price {
    font-size: 66px;
    @include cfont(600);
    color: #ffe758;
  }
  .activity-expires {
    margin-top: 50px;
    color: #ffffff;
  }
  .activity-btn {
    width: 280px;
    height: 60px;
    margin-top: 56px;
    background: #ffc100;
    border-radius: 30px;
    font-size: 24px;
    @include cfont(500);
    color: #000000;
    position: relative;
    transition: all 0.2s;
    &:hover {
      background-color: rgba(#ffc100, 0.9);
    }
  }
  @media screen and (max-width: 1441px) {
    .activity-expires {
      margin-top: 40px;
    }
    .activity-btn {
      margin-top: 46px;
    }
  }
}
.left2 {
  .tips {
    width: 267px;
    height: 39px;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 1360px) {
  .right2 {
    .pic {
      width: 479px !important;
      margin-left: 16px !important;
    }
  }

  .left2 {
    .title1 {
      font-size: 35px !important;
      line-height: 40px !important;
    }

    .title2 {
      font-size: 13px !important;
      margin-top: 20px !important;
      line-height: 20px !important;
    }

    .title3 {
      font-size: 13px !important;
      margin-top: 20px !important;
      line-height: 20px !important;
    }

    .btn {
      margin-top: 30px !important;
      width: 250px !important;
      height: 47px !important;
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: 1600px) {
  .right2 {
    .pic {
      width: 509px !important;
      margin-left: 26px !important;
    }
  }

  .left2 {
    .title1 {
      font-size: 37px !important;
      line-height: 43px !important;
    }

    .title2 {
      font-size: 14px !important;
      margin-top: 21px !important;
      line-height: 16px !important;
    }

    .title3 {
      font-size: 14px !important;
      margin-top: 16px !important;
      line-height: 21px !important;
    }

    .btn {
      margin-top: 30px !important;
      width: 270px !important;
      height: 50px !important;
      font-size: 17px !important;
    }
  }
}

@media screen and (max-width: 1023px) {
  .right2 {
    // .pic {
    //     width: 383px !important;
    //     margin-left: 20px !important;
    // }
  }
}

@media screen and (max-width: 1023px) {
  .main {
    height: 740px !important;
  }
  .top {
    padding-top: 66px;
  }
  .topwrap {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-top: 66px;
    .left {
      flex: 1;

      position: relative !important;
      .title1 {
        font-size: 32px;
        line-height: 48px;
        padding: 0 20px;
        margin-top: 30px;
      }
      .start {
        width: 240px;
        height: 48px;
        line-height: 48px;
        margin-top: 32px !important;
        margin-left: 20px !important;
      }
    }
    .right {
      flex: 1;
      margin: 0 !important;
      .tip-box {
        width: 99px;
        height: 46px;
        padding-left: 21px !important;
        left: 15px;
        .smallcn {
          width: 9px;
          height: 9px;
        }
        .title {
          padding-top: 11px;
          font-size: 7px;
          line-height: 10px;
          margin-bottom: 2px;
        }
        .num {
          line-height: 17px;
          font-size: 13px;
        }
      }
      .pic {
        top: 23px !important;
        width: 366px;
        height: 307px;
        bottom: 0;
      }
      .msk-box {
        width: 111px;
        height: 123px;
        right: 0;
        top: 83px;
        padding: 10px 14px;
        .msk-title {
          font-size: 7px;
          line-height: 10px;
          margin-bottom: 5px;
        }
        .msk-desc {
          line-height: 17px;
          font-size: 13px;
        }
        .msk-io {
          left: 11px;
          bottom: 70px !important;
          .icon {
            width: 17px;
            height: 17px;
          }
        }
        .line-card {
          display: flex;
          height: 55px;
          align-items: flex-end;
          margin-top: 17px;

          .line {
            flex: 1;
            height: 100%;
            width: 17%;
            // margin-right: 3%;
            border-radius: 3px;
            background: rgba(255, 10, 10, 0.28);

            &:nth-child(1) {
              height: 28px;
            }

            &:nth-child(2) {
              height: 17px;
            }

            &:nth-child(3) {
              height: 35px;
            }

            &:nth-child(4) {
              background: linear-gradient(180deg, #ff6c3e 0%, #ff3e5f 100%);
            }

            &:last-child {
              height: 31px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .left2 {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative !important;
    .title1 {
      font-size: 32px !important;
      line-height: 44px !important;
    }
    .tips {
      display: none;
    }
    .title2 {
      display: none;
      font-size: 12px !important;
      margin-top: 16px !important;
      line-height: 16px !important;
    }

    .title3 {
      display: none;
      font-size: 12px !important;
      margin-top: 16px !important;
      line-height: 16px !important;
    }

    .btn {
      margin-top: 28px !important;
      width: 200px !important;
      height: 37px !important;
      font-size: 13px !important;
    }
  }
  .right2 {
    margin: 0;
    flex: 1;
    .pic {
      top: auto !important;
      width: 339px !important;
      height: 317px !important;
      bottom: 0 !important;
    }
  }
}

.pc-block {
  width: 50px;
  height: 44px;
  background: #ff3e5f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  @include cfont(600);
  color: #fff;
}

.m-block {
  width: 38px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  font-size: 22px;
  @include cfont(700);
}

.banner-block {
  flex: 1;
  flex-shrink: 0;
  width: 22px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  font-size: 12px;
  @include cfont(600);
}

.dialog-block {
  width: 44px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffc100;
  border-radius: 4px;
  color: #000 !important;
  font-size: 24px;
  @include cfont(600);
}
